import {ConfigService} from "./ConfigService";
import {HttpClient} from "aurelia-http-client";

export class IdLogikService {
    container;
    wrapper;
    encounterId;

    private inited = false;
    private sessionId = 'Berlin-23450_f0b8b112e5694e6d8176bc3338212fbe';

    constructor() {
    }

    public static get configEnabled() {
        return ConfigService.cfg.features && ConfigService.cfg.features.idLogikMedications && ConfigService.cfg.features.idLogikMedications.enabled || false;
    }

    public static get configUrl() {
        return ConfigService.cfg.features && ConfigService.cfg.features.idLogikMedications && ConfigService.cfg.features.idLogikMedications.url || '';
    }

    public static get configUser() {
        return ConfigService.cfg.features && ConfigService.cfg.features.idLogikMedications && ConfigService.cfg.features.idLogikMedications.user || '';
    }

    public static get configKeyDev() {
        return ConfigService.cfg.features && ConfigService.cfg.features.idLogikMedications && ConfigService.cfg.features.idLogikMedications.keys && ConfigService.cfg.features.idLogikMedications.keys.developer || '';
    }

    public static get configKeyLogik() {
        return ConfigService.cfg.features && ConfigService.cfg.features.idLogikMedications && ConfigService.cfg.features.idLogikMedications.keys && ConfigService.cfg.features.idLogikMedications.keys.logik || '';
    }

    public static get configKeyMedics() {
        return ConfigService.cfg.features && ConfigService.cfg.features.idLogikMedications && ConfigService.cfg.features.idLogikMedications.keys && ConfigService.cfg.features.idLogikMedications.keys.medics || '';
    }

    init() {
        this.container = document.createElement('iframe');
        this.container.name = 'iframe_idlogik_container';
        this.container.id = 'iframe_idlogik_container';
        this.container.src = this.getUrl();
        this.container.style.border = 'none';
        this.container.style.width = '100%';
        this.container.style.height = '100%';
        this.container.style.overflow = 'hidden';
        this.container.style.backgroundColor = "transparent";
        this.container.frameBorder = "0";
        this.container.allowTransparency = "true";
    }

    attach(wrapper, encounterId) {
        if (!this.inited) {
            this.init();
        }

        this.encounterId = encounterId;
        this.wrapper = wrapper;

        wrapper.appendChild(this.container);
    }

    async login() {
        if (!IdLogikService.configEnabled) {
            return;
        }

        const url = `${IdLogikService.configUrl}/axis2/idlogikrest?` + '' +
            'serviceName=user.Login' +
            '&NAME=Berlin-23450' +
            '&COMPUTER=localhost' +
            '&IP=127.0.0.1' +
            '&LICENCE=' +
            `${IdLogikService.configKeyDev}` +
            `|${IdLogikService.configKeyLogik}` +
            `|${IdLogikService.configKeyMedics}` +
            '&__format__=JSON';

        let client = new HttpClient();
        client.configure(o => {
            o.withHeader('Accept', 'application/json');
            o.withHeader('Content-Type', 'application/json');
        });

        try {
            const result = JSON.parse((await client.get(url)).response);

            this.sessionId = result.resultItems[0].value;
        } catch (e) {
        }
    }

    logout() {
        if (!IdLogikService.configEnabled) {
            return;
        }

        let client = new HttpClient();
        client.configure(o => {
            o.withHeader('Accept', 'application/json');
            o.withHeader('Content-Type', 'application/json');
        });

        try {
            client.get(`${IdLogikService.configUrl}/axis2/idlogikrest?serviceName=user.Logoff&SESSION_ID=${this.sessionId}`);
        } catch (e) {
        }
    }

    getUrl() {
        return `${IdLogikService.configUrl}/webcomponents/webcomponents.html?` +
            'name=Curve' +
            `&signature=Arzt` +
            '&authentification=1' +
            '&role=Arzt' +
            `&naturalCaseId=${this.encounterId}` + //'&naturalCaseId=CHF_01'
            '&dsChangeManager=1' +
            '&infoManager=1' +
            '&selectTime=1' +
            `&sessionId=${this.sessionId}` +
            '&titleWidth=400' +
            '&scrollbar=0';
    }
}
